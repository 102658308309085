import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const CaseStudyPage = () => (
  <Layout>

    <title>Ryan Phung - Interaction Designer - Case studies</title>

    <iframe 
      className="figma-iframe"
      style={{
        border: `1px solid rgba(0, 0, 0, 0.1)`,
        width: `calc(100vw - 8px)`,
        height: `calc(100vh - 8px)`,
        padding: `4px`,
        position: `absolute`,
      }}
      src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FTNVuFcNukP9WNFWua6g3DF%2FCase-studies%3Fpage-id%3D1%253A6%26node-id%3D782%253A4495%26viewport%3D742%252C484%252C0.03%26scaling%3Dscale-down%26starting-point-node-id%3D782%253A4495" 
      allowFullScreen>  
    </iframe>

  </Layout>
)

export const Head = () => <Seo title="Case-studies" />

export default CaseStudyPage
